import React, { useContext } from 'react';
import InverterList from './components/InverterList';
import ResultList from './components/ResultsList';
import styles from './SectionResults.module.css';
import { GlobalContext } from '../../../../context/GlobalContext';
import Filters from './components/Filters';
import PdfButton from './results-pdf/PdfButton';

function SectionResults() {
  const global = useContext(GlobalContext);
  const { onReturn } = global;
  const classDivResult = 'div-results';
  const maxScreenSize = 768;

  const backBtn = (
    <button
      type="button"
      className="btn btn-primary"
      onClick={ () => onReturn() }
      style={ {
        backgroundColor: '#114579',
        border: '1px solid #114579',
        width: 'auto',
        fontFamily: 'Museo Sans Rounded',
        fontSize: '16px',
        marginLeft: window.innerWidth < maxScreenSize && '24px',
      } }
    >
      Return
    </button>);

  return (
    <>
      <div className={ styles[classDivResult] }>
        <h3>Results</h3>
      </div>
      <div className={ styles['list-inverter-container'] }>
        <div className={ styles['results-container'] }>
          <div className={ styles[classDivResult] }>
            <ResultList />
            <Filters />
            { window.innerWidth > maxScreenSize
        && (
          <>
            { backBtn }
            <PdfButton />
          </>
        ) }
          </div>
          <div className={ styles[classDivResult] }>
            <InverterList />
          </div>
        </div>
        { window.innerWidth < maxScreenSize
        && (
          <div className={ styles['buttons-container'] }>
            { backBtn }
            <PdfButton />
          </div>
        )}
      </div>
    </>
  );
}

export default SectionResults;
