import { BrowserRouter, Route } from 'react-router-dom';
import { useState } from 'react';
import { GlobalStorage } from './context/GlobalContext';
import PortuguesePageDataInputs from './portuguese/pages/PortuguesePageDataInputs';
import PortuguesePageResults from './portuguese/pages/PortuguesePageResults';
import styles from './App.module.css';
import HeaderPortuguese from './portuguese/components/header/PortugueseHeader';
import SpanishHeader from './spanish/components/header/SpanishHeader';
import SpanishPageDataInputs from './spanish/pages/SpanishPageDataInputs';
import SpanishPageResults from './spanish/pages/SpanishPageResults';
import EnglishHeader from './english/components/header/EnglishHeader';
import EnglishPageDataInputs from './english/pages/EnglishPageDataInputs';
import EnglishPageResults from './english/pages/EnglishPageResults';

function App() {
  const [language, setLanguage] = useState('Português');

  return (
    <BrowserRouter>
      <h5
        className={ styles['victron-advice'] }
      >
        This is not an official Victron Energy product.
      </h5>
      <GlobalStorage>
        { language === 'Português' && (
          <>
            <HeaderPortuguese setLanguage={ setLanguage } language={ language } />
            <Route exact path="/" component={ PortuguesePageDataInputs } />
            <Route path="/results" component={ PortuguesePageResults } />
          </>
        ) }
        { language === 'Español' && (
          <>
            <SpanishHeader setLanguage={ setLanguage } language={ language } />
            <Route exact path="/" component={ SpanishPageDataInputs } />
            <Route path="/results" component={ SpanishPageResults } />
          </>
        ) }
        { language === 'English' && (
          <>
            <EnglishHeader setLanguage={ setLanguage } language={ language } />
            <Route exact path="/" component={ EnglishPageDataInputs } />
            <Route path="/results" component={ EnglishPageResults } />
          </>
        ) }
        <>
          <a
            href="https://www.linkedin.com/in/gabrielscapim/"
            target="_blank"
            rel="noreferrer"
          >
            <h5
              className={ styles.signature }
            >
              Developed by Electrical Engineer Gabriel Scapim
            </h5>
          </a>
          <h5 className={ styles.version }>
            Version: 1.0.0
          </h5>
        </>
      </GlobalStorage>
    </BrowserRouter>
  );
}

export default App;
