import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import CloseIcon from '../CloseIcon';
import CloseButton from '../CloseButton';
import ResultLoadsTable from '../../sections/section-results/components/ResultLoadsTable';
import { GlobalContext } from '../../../../context/GlobalContext';
import styles from './PhasesBalanceModal.module.css';

function PhasesBalanceModal({ modalIsOpen, setIsOpen }) {
  const global = React.useContext(GlobalContext);
  const {
    phaseOneLoads,
    phaseTwoLoads,
    phaseThreeLoads,
    phaseOnePower,
    phaseTwoPower,
    phaseThreePower,
  } = global.loadsPhases;

  const phasesObj = [{
    name: 'um',
    loads: phaseOneLoads,
    totalPower: phaseOnePower,
  },
  {
    name: 'dois',
    loads: phaseTwoLoads,
    totalPower: phaseTwoPower,
  },
  {
    name: 'três',
    loads: phaseThreeLoads,
    totalPower: phaseThreePower,
  },
  ];

  const customStyles = {
    content: {
      fontFamily: 'Museo Sans Rounded',
      fontSize: '14px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '500px',
      backgroundColor: '#FAF9F6',
      width: 'auto',
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={ modalIsOpen }
      onRequestClose={ () => closeModal() }
      style={ customStyles }
    >
      <div className={ styles['modal-container'] }>
        <CloseIcon closeModal={ () => closeModal() } />
        <div className={ styles['phase-power-container'] }>
          <h3>Potência total em cada fase</h3>
          { phasesObj.map(({ name, totalPower }) => (
            totalPower !== 0
              && (
                <p key={ `result-load-${name}` }>
                  { `Fase ${name}: ${totalPower} W` }
                </p>)
          )) }
          <p className={ styles['phase-inverter-warning'] }>
            <i className="bi bi-info-circle">   </i>
            A potência do invesor é calculada a partir da fase com
            menor valor, porém, em sistemas polifásicos, cada fase
            deve ter o mesmo modelo de inversor.
          </p>
        </div>
        { phasesObj.map(({ name, loads, totalPower }) => (
          totalPower !== 0
            && <ResultLoadsTable
              key={ `result-load-${name}` }
              name={ name }
              loads={ loads }
              totalPower={ totalPower }
            />
        )) }
        <CloseButton closeModal={ () => closeModal() } />
      </div>
    </Modal>
  );
}

PhasesBalanceModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default PhasesBalanceModal;
