/* eslint-disable max-lines */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { pvPower } from '../features/pvPower';
import { batteryEnergy } from '../features/batteryEnergy';
import { phasesCalculator } from '../features/phasesCalculator';
import { suggestInverter } from '../features/suggestInverter';
import { setLocalStorage } from '../helpers/setLocalStorage';
import { getLocalStorage } from '../helpers/getLocalStorage';

export const GlobalContext = React.createContext();

export function GlobalStorage({ children }) {
  const loadsInitialState = {
    loadName: '',
    loadPhases: 'Fase única (1)',
    loadPower: 500,
    loadQuantity: 1,
    loadUse: 1,
  };
  const initialState = {
    clientName: '',
    systemInformations: '',
    technicalName: '',
    hsp: 5,
    inverterVoltage: 230,
    inverterCharger: true,
    batteryAutonomy: 48,
    batteryTension: 24,
    batteryComposition: 'Chumbo ácido',
    batteryDischarge: 50,
    batteryEfficiency: 80,
    invertersFilters: false,
    acInputsInvertersFilter: 1,
  };
  const [state, setState] = useState(() => (
    getLocalStorage('state') !== null
      ? { ...getLocalStorage('state') }
      : { ...initialState, ...loadsInitialState }
  ));
  const [loads, setLoads] = useState(() => (
    getLocalStorage('loads') !== null
      ? [...getLocalStorage('loads')]
      : []
  ));
  const [results, setResults] = useState(() => (
    getLocalStorage('results') !== null
      ? { ...getLocalStorage('results') }
      : {}
  ));
  const [loadsPhases, setLoadsPhases] = useState(() => (
    getLocalStorage('loadsPhases') !== null
      ? { ...getLocalStorage('loadsPhases') }
      : {}
  ));
  const [invertersSuggest, setInvertersSuggest] = useState(() => (
    getLocalStorage('invertersSuggest') !== null
      ? { ...getLocalStorage('invertersSuggest') }
      : {}
  ));
  // const [results, setResults] = useState({});
  // const [loadsPhases, setLoadsPhases] = useState({});
  // const [invertersSuggest, setInvertersSuggest] = useState([]);
  const [inverterPowerModalIsOpen, setInverterPowerModalIsOpen] = useState(false);
  const [maxInverterPowerReach, setMaxInverterPowerReach] = useState(false);

  useEffect(() => {
    setLocalStorage('state', state);
    setLocalStorage('loads', loads);
    setLocalStorage('results', results);
    setLocalStorage('loadsPhases', loadsPhases);
    setLocalStorage('invertersSuggest', invertersSuggest);
  }, [invertersSuggest, loads, loadsPhases, results, state]);

  const history = useHistory();

  function handleChange({ target }) {
    const { name } = target;
    let { value } = target;
    if (target.type === 'number'
    || name === 'batteryTension'
    || name === 'inverterVoltage'
    || name === 'acInputsInvertersFilter') {
      value = Number(target.value);
    } else if (target.type === 'checkbox') {
      value = target.checked;
    }
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  // setState((prevState) => ({ ...prevState, ...updatedValues }));

  function addLoad() {
    const { loadName,
      loadPower,
      loadUse,
      loadQuantity,
      loadPhases,
    } = state;

    const newLoad = {
      loadName,
      loadPower,
      loadUse,
      loadQuantity,
      loadPhases,
    };

    for (let index = 0; index < loadQuantity; index += 1) {
      setLoads((prevLoads) => [...prevLoads, { ...newLoad, id: prevLoads.length }]);
    }

    setState((prevState) => ({ ...prevState, ...loadsInitialState }));
  }

  function removeLoad(id) {
    const newLoads = loads.filter((load) => load.id !== id);
    setLoads(newLoads);
  }

  function reset() {
    setLoads([]);
    setState(() => ({ ...initialState, ...loadsInitialState }));
  }

  useEffect(() => {
    if (!state.inverterCharger) {
      setLoads((prevLoads) => prevLoads.map((prevLoad) => ({
        ...prevLoad,
        loadPhases: 'Fase única (1)',
      })));
    }
  }, [state.inverterCharger]);

  useEffect(() => {
    const { hsp,
      batteryAutonomy,
      batteryDischarge,
      batteryTension,
      batteryEfficiency,
      inverterVoltage,
      inverterCharger,
      acInputsInvertersFilter,
      invertersFilters,
    } = state;

    const totalLoadsEnergy = loads.reduce((acc, cv) => acc
    + (cv.loadPower * cv.loadUse), 0);

    const totalLoadsPower = loads.reduce((acc, cv) => acc
    + cv.loadPower, 0);

    const pvPowerValue = pvPower(totalLoadsEnergy, batteryAutonomy, hsp);

    const batteryEnergyValue = batteryEnergy({
      totalLoadsEnergy,
      batteryAutonomy,
      batteryDischarge,
      batteryTension,
      batteryEfficiency,
    });

    setLoadsPhases({ ...phasesCalculator(
      loads,
      batteryTension,
      inverterVoltage,
      inverterCharger,
    ).loadsPhases });

    setInvertersSuggest(suggestInverter(
      {
        inverterCharger,
        batteryTension,
        inverterVoltage,
        inverterPower: loadsPhases.inverterPower,
      },
      {
        invertersFilters,
        acInputsInvertersFilter,
      },
    ));

    setResults({
      totalLoadsEnergy,
      totalLoadsPower,
      pvPower: pvPowerValue,
      batteryEnergy: batteryEnergyValue,
    });
  }, [loads, loadsPhases.inverterPower, state]);

  // Verify the inverter power
  useEffect(() => {
    const POWER_FACTOR = 0.8;
    const MAX_INVERTER_POWER = 45000;
    if ((loadsPhases.inverterPower / POWER_FACTOR) > MAX_INVERTER_POWER) {
      setInverterPowerModalIsOpen(true);
      setMaxInverterPowerReach(true);
    } else if ((loadsPhases.inverterPower / POWER_FACTOR) < MAX_INVERTER_POWER) {
      setMaxInverterPowerReach(false);
    }
  }, [loadsPhases.inverterPower, maxInverterPowerReach]);

  // Battery efficiency and discharge suggestion
  useEffect(() => {
    if (state.batteryComposition === 'Plomo y ácido'
    || state.batteryComposition === 'Chumbo ácido') {
      setState((prevState) => ({ ...prevState,
        batteryEfficiency: 80,
        batteryDischarge: 50 }));
    } else if (state.batteryComposition === 'Litio') {
      setState((prevState) => ({ ...prevState,
        batteryEfficiency: 95,
        batteryDischarge: 80 }));
    }
  }, [state.batteryComposition]);

  function onSubmit() {
    history.push('/results');
  }

  function onReturn() {
    history.push('/');
  }

  return (
    <GlobalContext.Provider
      value={ {
        state,
        setState,
        handleChange,
        onSubmit,
        onReturn,
        addLoad,
        removeLoad,
        results,
        loads,
        loadsPhases,
        invertersSuggest,
        inverterPowerModalIsOpen,
        setInverterPowerModalIsOpen,
        maxInverterPowerReach,
        reset,
        setInvertersSuggest,
      } }
    >
      { children }
    </GlobalContext.Provider>
  );
}

GlobalStorage.propTypes = {
  children: PropTypes.node.isRequired,
};
