import React from 'react';
import SectionResults from '../components/sections/section-results/SectionResults';
import styles from './PageResults.module.css';

function SpanishPageResults() {
  return (
    <main className={ styles['page-results'] }>
      <section className={ styles['section-results'] }>
        <SectionResults />
      </section>
    </main>
  );
}

export default SpanishPageResults;
