import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import CloseButton from '../CloseButton';
import { GlobalContext } from '../../../../context/GlobalContext';
import styles from './InverterChargerWarningModal.module.css';

function InverterChargerWarningModal({ modalIsOpen, setIsOpen }) {
  const global = useContext(GlobalContext);
  const { setState } = global;

  function closeModal() {
    setIsOpen(false);
  }

  function btnNoInverterCharger() {
    setState((prevState) => ({ ...prevState, inverterCharger: false }));
    setIsOpen(false);
  }

  const modalStyles = {
    content: {
      fontFamily: 'Museo Sans Rounded',
      fontSize: '14px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      maxHeight: '500px',
      backgroundColor: '#FAF9F6',
      width: 'auto',
    },
  };

  const iconStyles = {
    fontSize: '100px',
    color: '#f8bb86',
  };

  const btnNoInverterChargerStyles = {
    backgroundColor: '#4790d0',
    border: '1px solid #4790d0',
    marginTop: '12px',
    width: 'auto',
    fontFamily: 'Museo Sans Rounded',
    fontSize: '16px',
  };

  return (
    <Modal
      ariaHideApp={ false }
      isOpen={ modalIsOpen }
      onRequestClose={ () => closeModal() }
      style={ modalStyles }
      className={ styles.modal }
    >
      <div className={ styles['modal-container'] }>
        <i className="bi bi-exclamation-circle" style={ iconStyles }> </i>
        <h4>
          Tem certeza?
        </h4>
        <p>
          O sistema terá apenas uma fase e todas as cargas
          adicionadas serão consideradas monofásicas
        </p>
        <div className={ styles['buttons-container'] }>
          <button
            type="button"
            className="btn btn-primary"
            onClick={ btnNoInverterCharger }
            style={ btnNoInverterChargerStyles }
          >
            Sim, não utilizar inversor/carregador!
          </button>
          <CloseButton closeModal={ () => closeModal() } />
        </div>
      </div>
    </Modal>
  );
}

InverterChargerWarningModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default InverterChargerWarningModal;
