export const isInputNegative = (input) => (
  input <= 0
);

export const isInputNotNumber = (input) => (
  typeof (input) !== 'number'
);

export const isInputNumberMax = (input, max) => (
  input > max
);

export const isInputMaxLength = (input, max) => (
  input.length > max
);

export const isInputNumberNotInteger = (input) => (
  !Number.isInteger(input)
);
