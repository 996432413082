import React from 'react';
import PropTypes from 'prop-types';
import styles from './CloseIcon.module.css';

function CloseIcon({ closeModal }) {
  return (
    <button
      type="button"
      onClick={ () => closeModal() }
      className={ styles['close-button-icon'] }
    >
      <i className="bi bi-x-circle"> </i>
    </button>
  );
}

CloseIcon.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CloseIcon;
