// This function will be called by phasesCalculator

import invertersChargers from '../data/invertersCharges';
import inverters from '../data/inverters';

export const suggestInverter = (noFilterParams, filters) => {
  const {
    inverterCharger,
    batteryTension,
    inverterVoltage,
    inverterPower,
  } = noFilterParams;

  const {
    invertersFilters,
    acInputsInvertersFilter,
  } = filters;

  let data = inverterCharger ? invertersChargers : inverters;

  // // Filters
  if (inverterCharger && invertersFilters) {
    data = data.filter(({ acInputs }) => (
      acInputs === acInputsInvertersFilter
    ));
  }

  const filterByVoltage = data.filter(({ batteryVoltage, voltage }) => (
    batteryTension === batteryVoltage && inverterVoltage === voltage
  ));

  const higherPowerInverter = Math.max(...filterByVoltage.map((inv) => inv.wattsPower));

  const closestInverterPower = filterByVoltage.reduce((prev, curr) => (
    Math.abs(curr.wattsPower - inverterPower)
    < Math.abs(prev.wattsPower - inverterPower) ? curr : prev
  ));

  const bestInverterPower = inverterPower > higherPowerInverter
    ? '' : closestInverterPower.wattsPower;

  // const bestInverterPower = inverterPower > higherPowerInverter
  //   ? higherPowerInverter : closestInverterPower.wattsPower;

  const suggestedInverters = filterByVoltage.filter(({ wattsPower }) => (
    wattsPower === bestInverterPower
  ));

  const otherOptionsInverters = filterByVoltage.sort((a, b) => (
    a.wattsPower - b.wattsPower));

  return {
    otherOptionsInverters,
    suggestedInverters,
  };
};
