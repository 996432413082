/* eslint-disable no-magic-numbers */
export const verifySubmit = (state, loads, maxInverterPowerReach) => {
  const { hsp,
    batteryAutonomy,
    batteryDischarge,
    batteryEfficiency,
    loadPower,
    loadQuantity,
    loadUse,
  } = state;

  const numberInputs = [hsp,
    batteryAutonomy,
    batteryDischarge,
    batteryEfficiency,
    loadPower,
    loadQuantity,
    loadUse,
  ];

  const maxInput = [hsp, batteryDischarge, batteryEfficiency];

  const verifyMaxInput = maxInput.some((input) => input > 99);

  const verifyTypeNegative = numberInputs.some((input) => (
    typeof (input) !== 'number' || input <= 0));

  const verifyLoads = loads.length === 0;

  const verifyBatteryAutonomy = batteryAutonomy > 240;

  if (verifyMaxInput
    || verifyTypeNegative
    || verifyLoads
    || maxInverterPowerReach
    || verifyBatteryAutonomy) {
    return true;
  }
};
