import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResultCard.module.css';
import PhasesBalanceModal from '../../../modal/phases-balance/PhasesBalanceModal';

function ResultCard({ resultName, resultValue, info }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  return (
    <tr className={ styles['result-line'] }>
      { info
        ? (
          <td>
            <button
              type="button"
              onClick={ () => openModal() }
              className="btn btn-primary"
              style={ {
                backgroundColor: '#114579',
                border: '1px solid #114579',
                // marginTop: '16px',
                width: 'auto',
                fontFamily: 'Museo Sans Rounded',
                fontSize: '12px',
                color: 'white',
                padding: '6px 12px',
                marginTop: '16px',
              } }
            >
              {/* <i className="bi bi-info-circle"> </i> */}
              { ` ${resultName}`}
            </button>
            <PhasesBalanceModal
              modalIsOpen={ modalIsOpen }
              setIsOpen={ setIsOpen }
            />
          </td>)
        : (
          <td className={ styles['result-name'] }>{ resultName }</td>
        )}
      <td>{resultValue}</td>
    </tr>
  );
}

ResultCard.propTypes = {
  resultName: PropTypes.string.isRequired,
  resultValue: PropTypes.string.isRequired,
  info: PropTypes.bool.isRequired,
};

export default ResultCard;
