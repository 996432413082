import React from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import styles from './Input.module.css';

function Input({
  id,
  label,
  placeHolder,
  type,
  max,
  min,
  step,
  inputValue,
  name,
  maxInputLength,
  componentStyles,
}) {
  const inputStyle = {
    color: '#003366',
    ...componentStyles,
  };

  const global = React.useContext(GlobalContext);

  return (
    <div
      className={ type === 'number'
        ? `form-group ${styles['form-input-container-medium']}`
        : `form-group ${styles['form-input-container']}` }
      // className={ `form-group ${styles['form-input-container']}` }
      // style={ { width: type === 'number' ? '45%' : '' } }
    >
      <label
        htmlFor={ id }
      >
        { label }
      </label>

      <input
        type={ type }
        id={ id }
        placeholder={ placeHolder }
        className={ ` ${styles['form-input']} form-control` }
        min={ min }
        max={ max }
        step={ step }
        style={ inputStyle }
        maxLength={ maxInputLength }
        value={ inputValue }
        name={ name }
        onChange={ (event) => global.handleChange(event) }
      />
    </div>
  );
}

Input.propTypes = {}.isRequired;

export default Input;
