/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */

const invertersCharges = [
  {
    id: 1,
    type: 'inverter-charger',
    name: 'Multiplus Compact 12/2000/80-50',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 80,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1278592747_upload_documents_1600_640_q90-MultiPlus-Compact-12-2000-80-30_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 2,
    type: 'inverter-charger',
    name: 'Multiplus 12/2000/80-50',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 80,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242035045_upload_documents_1600_640_q90-MultiPlus-12-3000-120-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 3,
    type: 'inverter-charger',
    name: 'Multiplus 12/3000/120-50',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242035045_upload_documents_1600_640_q90-MultiPlus-12-3000-120-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 4,
    type: 'inverter-charger',
    name: 'Multiplus-II 12/3000/120-50',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674468116_upload_documents_1600_640_q90-PMP122305010_Multiplus-II%2012V%203kVA_120-32%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-3kVA-120V-EN.pdf',
  },
  {
    id: 5,
    type: 'inverter-charger',
    name: 'Multiplus-II 12/3000/120-50 2x120V',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674468116_upload_documents_1600_640_q90-PMP122305010_Multiplus-II%2012V%203kVA_120-32%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-3kVA-2x120V-EN-.pdf',
  },
  {
    id: 6,
    type: 'inverter-charger',
    name: 'Quattro 12/5000/220-100/100',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 220,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1208246505_upload_documents_1600_640_q90-quattro_24-5000-120-120_front-300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3-10kVA-120V-EN-.pdf',
  },
  {
    id: 7,
    type: 'inverter-charger',
    name: 'Multiplus Compact 24/2000/50-50',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1611827211_upload_documents_1600_640_q90-CMP242200100_MultiPlus%20Compact%2024_2000_50-50%20120V%20VE-Bus%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 8,
    type: 'inverter-charger',
    name: 'Multiplus 24/2000/50-50',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242036464_upload_documents_1600_640_q90-MultiPlus-24-3000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 9,
    type: 'inverter-charger',
    name: 'Multiplus 24/3000/70-50',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242036464_upload_documents_1600_640_q90-MultiPlus-24-3000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 10,
    type: 'inverter-charger',
    name: 'Multiplus-II 24/3000/70-50',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1599733614_upload_documents_1600_640_q90-MultiPlus-II%2024V%203kVA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-3kVA-120V-EN.pdf',
  },
  {
    id: 11,
    type: 'inverter-charger',
    name: 'Quattro 24/5000/120-100/100',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 120,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1208246505_upload_documents_1600_640_q90-quattro_24-5000-120-120_front-300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3-10kVA-120V-EN-.pdf',
  },
  {
    id: 12,
    type: 'inverter-charger',
    name: 'Multiplus 48/2000/25-50',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 25,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450659_upload_documents_1600_640_q90-MultiPlus%2048V%203000VA%2035A.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Multiplus-inverter-charger_2kVA-and-3kVA-120V-US-EN.pdf',
  },
  {
    id: 13,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/3000/35-50',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1599733614_upload_documents_1600_640_q90-MultiPlus-II%2024V%203kVA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-3kVA-120V-EN.pdf',
  },
  {
    id: 14,
    type: 'inverter-charger',
    name: 'Quattro 48/3000/35-50/50',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1241690146_upload_documents_1600_640_q90-Quattro-12-3000-120-50-30_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3-10kVA-120V-EN-.pdf',
  },
  {
    id: 15,
    type: 'inverter-charger',
    name: 'Quattro 48/5000/70-100/100',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1236784333_upload_documents_1600_640_q90-Quattro-48-5000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3-10kVA-120V-EN-.pdf',
  },
  {
    id: 16,
    type: 'inverter-charger',
    name: 'Quattro 48/10000/140-100/100',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 10000,
    wattsPower: 8000,
    chargeCurrentHouseBattery: 140,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1277391255_upload_documents_1600_640_q90-Quattro-48-10000-140-front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3-10kVA-120V-EN-.pdf',
  },
  {
    id: 17,
    type: 'inverter-charger',
    name: 'MultiPlus 12/500/20-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 500,
    wattsPower: 400,
    chargeCurrentHouseBattery: 20,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1551885069_upload_documents_1600_640_q90-MultiPlus%2012V%20500VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 18,
    type: 'inverter-charger',
    name: 'MultiPlus 12/800/35-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 800,
    wattsPower: 640,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509444095_upload_documents_1600_640_q90-MultiPlus%2012V%20800VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 19,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 12/800/35-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 800,
    wattsPower: 640,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 20,
    type: 'inverter-charger',
    name: 'MultiPlus 12/1200/50-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1200,
    wattsPower: 960,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509445652_upload_documents_1600_640_q90-Multiplus%2012V%201200VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 21,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 12/1200/50-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1200,
    wattsPower: 960,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 22,
    type: 'inverter-charger',
    name: 'MultiPlus 12/1600/70-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1600,
    wattsPower: 1280,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1561122802_upload_documents_1600_640_q90-MultiPlus%2012_1600_70-16%20230V%20VE%20%28frontal%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 23,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 12/1600/70-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1600,
    wattsPower: 1280,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 24,
    type: 'inverter-charger',
    name: 'MultiPlus 12/2000/80-32',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 80,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1614770831_upload_documents_1600_640_q90-PMP122200000_MultiPlus%2012%202000VA%2080%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-2000-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 25,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 12/2000/80-32',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 80,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1278592747_upload_documents_1600_640_q90-MultiPlus-Compact-12-2000-80-30_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 26,
    type: 'inverter-charger',
    name: 'MultiPlus 12/3000/120-16',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242035045_upload_documents_1600_640_q90-MultiPlus-12-3000-120-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 27,
    type: 'inverter-charger',
    name: 'MultiPlus 12/3000/120-50',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242035045_upload_documents_1600_640_q90-MultiPlus-12-3000-120-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 28,
    type: 'inverter-charger',
    name: 'Multiplus-II 12/3000/120-32',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674468116_upload_documents_1600_640_q90-PMP122305010_Multiplus-II%2012V%203kVA_120-32%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 29,
    type: 'inverter-charger',
    name: 'Multiplus 12/3000/120-50',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242035045_upload_documents_1600_640_q90-MultiPlus-12-3000-120-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 30,
    type: 'inverter-charger',
    name: 'Quattro 12/5000/220-100/100',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 220,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1278342922_upload_documents_1600_640_q90-Quattro-12-5000-220-front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 31,
    type: 'inverter-charger',
    name: 'MultiPlus 24/500/10-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 500,
    wattsPower: 400,
    chargeCurrentHouseBattery: 10,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509444455_upload_documents_1600_640_q90-MulitPlus%2024V%20500VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 32,
    type: 'inverter-charger',
    name: 'MultiPlus 24/800/16-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 800,
    wattsPower: 640,
    chargeCurrentHouseBattery: 16,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509447636_upload_documents_1600_640_q90-MultiPlus%2024V%20800VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 33,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 24/800/16-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 800,
    wattsPower: 640,
    chargeCurrentHouseBattery: 16,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 34,
    type: 'inverter-charger',
    name: 'MultiPlus 24/1200/25-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1200,
    wattsPower: 960,
    chargeCurrentHouseBattery: 25,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509447874_upload_documents_1600_640_q90-MultiPlus%2024V%201200VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 35,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 24/1200/25-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1200,
    wattsPower: 960,
    chargeCurrentHouseBattery: 25,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 36,
    type: 'inverter-charger',
    name: 'MultiPlus 24/1600/40-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1600,
    wattsPower: 1280,
    chargeCurrentHouseBattery: 40,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1561122802_upload_documents_1600_640_q90-MultiPlus%2012_1600_70-16%20230V%20VE%20%28frontal%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 37,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 24/1600/40-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1600,
    wattsPower: 1280,
    chargeCurrentHouseBattery: 40,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1455534877_upload_documents_1600_640_q90-MultiPlus%20Compact%2012V%201600VA_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 38,
    type: 'inverter-charger',
    name: 'MultiPlus 24/2000/50-32',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1614770831_upload_documents_1600_640_q90-PMP122200000_MultiPlus%2012%202000VA%2080%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-2000-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 39,
    type: 'inverter-charger',
    name: 'MultiPlus Compact 24/2000/50-30',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 50,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1611827211_upload_documents_1600_640_q90-CMP242200100_MultiPlus%20Compact%2024_2000_50-50%20120V%20VE-Bus%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 40,
    type: 'inverter-charger',
    name: 'MultiPlus 24/3000/70-16',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242036464_upload_documents_1600_640_q90-MultiPlus-24-3000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 41,
    type: 'inverter-charger',
    name: 'MultiPlus 24/3000/70-50',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1242036464_upload_documents_1600_640_q90-MultiPlus-24-3000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 42,
    type: 'inverter-charger',
    name: 'Multiplus-II 24/3000/70-32',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1599733614_upload_documents_1600_640_q90-MultiPlus-II%2024V%203kVA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 43,
    type: 'inverter-charger',
    name: 'Quattro 24/3000/70-50/50',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1234950748_upload_documents_1600_640_q90-Quattro-24-3000-70_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 44,
    type: 'inverter-charger',
    name: 'MultiPlus-II 24/3000/70-32 GX',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674461676_upload_documents_1600_640_q90-Multiplus-II%2024V%203kVA%2070-32%20230V%20GX%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii-gx',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-GX-inverter-charger-EN-.pdf',
  },
  {
    id: 45,
    type: 'inverter-charger',
    name: 'MultiPlus 24/5000/120-100',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450658_upload_documents_1600_640_q90-MultiPlus%2048%205000%2070.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 46,
    type: 'inverter-charger',
    name: 'Multiplus-II 24/5000/120-50',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 120,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674462233_upload_documents_1600_640_q90-Multiplus-II%2024V%205kVA%20120-50%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 47,
    type: 'inverter-charger',
    name: 'Quattro 24/5000/120-100/100',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 120,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1264607453_upload_documents_1600_640_q90-Quattro-24-5000-120-230v_Front_300dpi_jpg.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 48,
    type: 'inverter-charger',
    name: 'Quattro 24/8000/200-100/100',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 8000,
    wattsPower: 6400,
    chargeCurrentHouseBattery: 200,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1309788779_upload_documents_1600_640_q90-Quattro_24-8000-200_front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 49,
    type: 'inverter-charger',
    name: 'MultiPlus 48/500/6-16',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 500,
    wattsPower: 400,
    chargeCurrentHouseBattery: 6,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509444240_upload_documents_1600_640_q90-MultiPlus%2048V%20500VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 50,
    type: 'inverter-charger',
    name: 'MultiPlus 48/800/9-16',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 800,
    wattsPower: 640,
    chargeCurrentHouseBattery: 9,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509447566_upload_documents_1600_640_q90-MultiPlus%2048V%20800VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 51,
    type: 'inverter-charger',
    name: 'MultiPlus 48/1200/13-16',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 1200,
    wattsPower: 960,
    chargeCurrentHouseBattery: 13,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509447826_upload_documents_1600_640_q90-MultiPlus%2048V%201200VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 52,
    type: 'inverter-charger',
    name: 'MultiPlus 48/1600/20-16',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 1600,
    wattsPower: 1280,
    chargeCurrentHouseBattery: 20,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509447826_upload_documents_1600_640_q90-MultiPlus%2048V%201200VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multi-500-va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-500VA-2000VA-EN.pdf',
  },
  {
    id: 53,
    type: 'inverter-charger',
    name: 'MultiPlus 48/2000/25-32',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 2000,
    wattsPower: 1600,
    chargeCurrentHouseBattery: 25,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450659_upload_documents_1600_640_q90-MultiPlus%2048V%203000VA%2035A.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 54,
    type: 'inverter-charger',
    name: 'MultiPlus 48/3000/35-16',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450659_upload_documents_1600_640_q90-MultiPlus%2048V%203000VA%2035A.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 55,
    type: 'inverter-charger',
    name: 'MultiPlus 48/3000/35-50',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450659_upload_documents_1600_640_q90-MultiPlus%2048V%203000VA%2035A.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 56,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/3000/35-32',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1527759788_upload_documents_1600_640_q90-MultiPlus-II%2048V%203kVA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 57,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/3000/35-32 GX',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    chargeCurrentHouseBattery: 35,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1558075035_upload_documents_1600_640_q90-MultiPlus-II%2048V%203kVA%2035-32%20230V%20GX%20%28frontal%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii-gx',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-GX-inverter-charger-EN-.pdf',
  },
  {
    id: 58,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/5000/70-50',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1548064249_upload_documents_1600_640_q90-MultiPlus-II%2048V%205kVA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 59,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/5000/70-50 GX',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1674462960_upload_documents_1600_640_q90-Multiplus-II%2048V%205kVA%2050-70%20230V%20GX%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii-gx',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-GX-inverter-charger-EN-.pdf',
  },
  {
    id: 60,
    type: 'inverter-charger',
    name: 'MultiPlus 48/5000/70-100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1453450658_upload_documents_1600_640_q90-MultiPlus%2048%205000%2070.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-inverter-charger--800VA-5kVA-EN.pdf',
  },
  {
    id: 61,
    type: 'inverter-charger',
    name: 'Quattro-II 48/5000/70-50/50',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1637069344_upload_documents_1600_640_q90-Quattro-II-48-5000-70-%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-II-inverter-charger-EN.pdf',
  },
  {
    id: 62,
    type: 'inverter-charger',
    name: 'Quattro 48/5000/70-100/100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    chargeCurrentHouseBattery: 70,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1236784333_upload_documents_1600_640_q90-Quattro-48-5000-70-50_front_300dpi.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 63,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/8000/110-100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 8000,
    wattsPower: 6400,
    chargeCurrentHouseBattery: 110,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1675675605_upload_documents_1600_640_q90-PMP482805000_MultiPlus-II%2048V%208kVA%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 64,
    type: 'inverter-charger',
    name: 'Quattro 48/8000/110-100/100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 8000,
    wattsPower: 6400,
    chargeCurrentHouseBattery: 110,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1278343186_upload_documents_1600_640_q90-Quattro-48-8000-110-front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 65,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/10000/140-100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 10000,
    wattsPower: 8000,
    chargeCurrentHouseBattery: 140,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1675674787_upload_documents_1600_640_q90-MultiPlus-II%2048V%2010kVA%20230V%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
  {
    id: 66,
    type: 'inverter-charger',
    name: 'Quattro 48/10000/140-100/100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 10000,
    wattsPower: 8000,
    chargeCurrentHouseBattery: 140,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1277391255_upload_documents_1600_640_q90-Quattro-48-10000-140-front.jpg',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 67,
    type: 'inverter-charger',
    name: 'Quattro 48/15000/200-100/100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 15000,
    wattsPower: 12000,
    chargeCurrentHouseBattery: 200,
    acInputs: 2,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1484924473_upload_documents_1600_640_q90-Quattro_48V%2015kVA_front.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/quattro',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Quattro-3kVA-15kVA-EN.pdf',
  },
  {
    id: 68,
    type: 'inverter-charger',
    name: 'Multiplus-II 48/15000/200-100',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 15000,
    wattsPower: 12000,
    chargeCurrentHouseBattery: 140,
    acInputs: 1,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1663057206_upload_documents_1600_640_q90-Multiplus-II%2048V%2015000VA%20200A%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters-chargers/multiplus-ii',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-MultiPlus-II-inverter-charger-EN.pdf',
  },
];

export default invertersCharges;
