import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '../CloseIcon';
import InverterCard from '../../sections/section-results/components/InverterCard';
import CloseButton from '../CloseButton';
import { GlobalContext } from '../../../../context/GlobalContext';
import styles from './OtherInvertersModal.module.css';

function OtherInvertersModal({ modalIsOpen, setIsOpen }) {
  const global = React.useContext(GlobalContext);
  const { state: { invertersFilters, inverterCharger }, invertersSuggest: {
    suggestedInverters,
    otherOptionsInverters,
  } } = global;

  const customStyles = {
    content: {
      fontFamily: 'Museo Sans Rounded',
      fontSize: '14px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '5%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '500px',
      backgroundColor: '#FAF9F6',
      width: 'auto',
    },
  };

  const otherInvertersRecommendation = otherOptionsInverters.map((otherInverter) => {
    const find = suggestedInverters.some((inverterSuggested) => (
      inverterSuggested.wattsPower <= otherInverter.wattsPower));

    if (find === true) {
      return { ...otherInverter, recommendation: 'Recomendado' };
    }
    return { ...otherInverter, recommendation: 'No recomendado: baja potencia' };
  });

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Modal
      isOpen={ modalIsOpen }
      onRequestClose={ () => closeModal() }
      style={ customStyles }
    >
      <div className={ styles['modal-container'] }>
        <CloseIcon closeModal={ () => closeModal() } />
        { (invertersFilters && inverterCharger)
        && <h4>Aviso: Filtros de preferencia aplicados a inversores</h4> }
        { otherInvertersRecommendation.map((
          { id,
            name,
            voltage,
            vaPower,
            chargeCurrentHouseBattery,
            imgUrl,
            infoUrl,
            batteryVoltage,
            datasheetUrl,
            recommendation,
          },
        ) => (
          <div
            className={ styles['inverter-container'] }
            key={ `inverter-${id}` }
          >
            <InverterCard
              imgUrl={ imgUrl }
              name={ name }
              infoUrl={ infoUrl }
              vaPower={ vaPower }
              chargeCurrentHouseBattery={ chargeCurrentHouseBattery }
              voltage={ voltage }
              batteryVoltage={ batteryVoltage }
              datasheetUrl={ datasheetUrl }
              id={ id }
              recommendation={ recommendation }
            />
          </div>
        )) }
        <CloseButton closeModal={ () => closeModal() } />
      </div>
    </Modal>
  );
}

OtherInvertersModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default OtherInvertersModal;
