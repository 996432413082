/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import SectionData from '../components/sections/section-data/SectionData';
import SectionLoads from '../components/sections/section-loads/SectionLoads';
import { GlobalContext } from '../../context/GlobalContext';
import styles from './PageDataInputs.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { verifySubmit } from '../../helpers/verifySubmit';

function SpanishPageDataInputs() {
  const global = React.useContext(GlobalContext);
  const { state, loads, maxInverterPowerReach } = global;

  return (
    <form className={ styles.form }>
      <section className={ styles['section-data'] }>
        <SectionData />
      </section>

      <section className={ styles['section-data'] }>
        <SectionLoads />
        <div className={ styles['div-button'] }>
          <button
            type="button"
            className="btn btn-primary"
            style={ {
              backgroundColor: '#114579',
              border: '1px solid #114579',
              width: '90%',
              fontFamily: 'Museo Sans Rounded',
              fontSize: '16px',
            } }
            onClick={ () => global.onSubmit() }
            disabled={ verifySubmit(state, loads, maxInverterPowerReach) }
          >
            Calcular
          </button>
        </div>
      </section>
    </form>
  );
}

export default SpanishPageDataInputs;
