import React from 'react';
import InverterCard from './InverterCard';
import styles from './InverterList.module.css';
import { GlobalContext } from '../../../../../context/GlobalContext';
import OtherInvertersModal from '../../../modal/other-inverters/OtherInvertersModal';

function InverterList() {
  const global = React.useContext(GlobalContext);
  const { invertersSuggest: {
    suggestedInverters,
  } } = global;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const iconStyles = {
    fontSize: '100px',
    color: '#f8bb86',
  };

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className={ styles['inverter-list-container'] }>
      <div className={ styles['title-container'] }>
        <h4>Inversores sugeridos</h4>
        <button
          type="button"
          className="btn btn-primary"
          onClick={ () => openModal() }
          style={ {
            backgroundColor: '#114579',
            border: '1px solid #114579',
            width: 'auto',
            fontFamily: 'Museo Sans Rounded',
            fontSize: '12px',
          } }
        >
          Ver todas las opciones
        </button>
        <OtherInvertersModal
          modalIsOpen={ modalIsOpen }
          setIsOpen={ setIsOpen }
        />
      </div>
      { suggestedInverters.length === 0
        && (
          <div className={ styles['no-inverter-suggested-advice'] }>
            <i className="bi bi-exclamation-circle" style={ iconStyles }> </i>
            <p>
              No se encontraron inversores, intente retirarte
              algunas cargas del sistema o póngase en contacto con un
              <span> </span>
              <a
                href="https://www.victronenergy.com.es/where-to-buy"
                target="_blank"
                rel="noreferrer"
                style={ { color: 'rgb(250, 129, 40)' } }
              >
                distribuidor de Victron Energy.
              </a>
            </p>
          </div>
        )}
      { suggestedInverters.map((
        { id,
          name,
          voltage,
          vaPower,
          chargeCurrentHouseBattery,
          imgUrl,
          infoUrl,
          batteryVoltage,
          datasheetUrl,
        },
      ) => (
        <InverterCard
          key={ `inverter-${id}` }
          imgUrl={ imgUrl }
          name={ name }
          infoUrl={ infoUrl }
          vaPower={ vaPower }
          chargeCurrentHouseBattery={ chargeCurrentHouseBattery }
          voltage={ voltage }
          batteryVoltage={ batteryVoltage }
          datasheetUrl={ datasheetUrl }
          id={ id }
        />
      )) }
    </div>
  );
}

export default InverterList;
