/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
const inverters = [
  {
    id: 1,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/250',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 2,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/375',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026314_upload_documents_1600_640_q90-Phoenix%2012V%20375VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 3,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/500',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 4,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/800',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509024702_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 5,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/1200',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1528378344_upload_documents_1600_640_q90-Phoenix-inverter-12V-1200-%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 6,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/3000',
    voltage: 120,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1580814855_upload_documents_1600_640_q90-Phoenix%20Inverter%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-3000VA-120V-EN.pdf',
  },
  {
    id: 7,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/250',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 8,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/375',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509022899_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20375VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 9,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/500',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 10,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/800',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509027605_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 11,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/1200',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1541513064_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%201200%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 12,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/3000',
    voltage: 120,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1580814855_upload_documents_1600_640_q90-Phoenix%20Inverter%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-3000VA-120V-EN.pdf',
  },
  {
    id: 13,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/250',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 14,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/375',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509022899_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20375VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 15,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/500',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 16,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/800',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509027605_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 17,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/1200',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1541513064_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%201200%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 18,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/3000',
    voltage: 120,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1580814855_upload_documents_1600_640_q90-Phoenix%20Inverter%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-3000VA-120V-EN.pdf',
  },
  {
    id: 19,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/250',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 20,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/375',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026314_upload_documents_1600_640_q90-Phoenix%2012V%20375VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 21,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/500',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 22,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/800',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509024702_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 23,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 12/1200',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1541513064_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%201200%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 24,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 12/1200',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508855457_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2012V%201600VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 25,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 12/1600',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1600,
    wattsPower: 1300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508855457_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2012V%201600VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 26,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 12/1600',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 1600,
    wattsPower: 1300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1547468297_upload_documents_1600_640_q90-Phoenix%20Inverter%2012V%201600VA%20Smart%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 27,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 12/2000',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508855040_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2012V%202000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 28,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 12/2000',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 2000,
    wattsPower: 1600,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1546963318_upload_documents_1600_640_q90-Phoenix%20Inverter%2012V%202000VA%20Smart%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 29,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 12/3000',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508855040_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2012V%202000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 30,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 12/3000',
    voltage: 230,
    batteryVoltage: 12,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1571381218_upload_documents_1600_640_q90-Phoenix%20inverter%20Smart%2012-3000VA-10%20%28Front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 31,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/250',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 32,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/375',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509022899_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20375VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 33,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/500',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 34,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/800',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509027605_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 35,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 24/1200',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1541513064_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%201200%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 36,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 24/1600',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1600,
    wattsPower: 1300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508856710_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2024V%201600VA%20%28right%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 37,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 24/1600',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 1600,
    wattsPower: 1300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2024V%201600VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 38,
    type: 'inverter',
    name: 'Phoenix Inverter Compact 24/2000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508855040_upload_documents_1600_640_q90-Phoenix%20Inverter%20Compact%2012V%202000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 39,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 24/2000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 2000,
    wattsPower: 1600,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2024V%202000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 40,
    type: 'inverter',
    name: 'Phoenix Inverter 24/3000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1580814855_upload_documents_1600_640_q90-Phoenix%20Inverter%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-compact',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 41,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 24/3000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20inverter%20Smart%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 42,
    type: 'inverter',
    name: 'Phoenix Inverter 24/5000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508854874_upload_documents_1600_640_q90-Phoenix%20Inverter%2024%205000%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 43,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 24/5000',
    voltage: 230,
    batteryVoltage: 24,
    vaPower: 5000,
    wattsPower: 4000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1615906688_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2024V%205000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 44,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/250',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 250,
    wattsPower: 200,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509026177_upload_documents_1600_640_q90-Phoenix%2012V%20250VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 45,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/375',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 375,
    wattsPower: 300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509022899_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20375VA%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 46,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/500',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 500,
    wattsPower: 400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509025992_upload_documents_1600_640_q90-Phoenix%20inverter%2012V%20500VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 47,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/800',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 800,
    wattsPower: 650,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1509027605_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%20800VA%20VE.Direct%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 48,
    type: 'inverter',
    name: 'Phoenix Inverter VE.Direct 48/1200',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 1200,
    wattsPower: 1000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1541513064_upload_documents_1600_640_q90-Phoenix%20inverter%2024V%201200%20%28top%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-vedirect-250va-800va',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-VE.Direct-250VA-1200VA-EN.pdf',
  },
  {
    id: 49,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 48/1600',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 1600,
    wattsPower: 1300,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2048V%201600VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 50,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 48/2000',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 2000,
    wattsPower: 1600,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2048V%202000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 51,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 48/3000',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1605794686_upload_documents_1600_640_q90-Phoenix%20inverter%20Smart%2048V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 52,
    type: 'inverter',
    name: 'Phoenix Inverter Smart 48/5000',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1615906792_upload_documents_1600_640_q90-Phoenix%20Inverter%20Smart%2048V%205000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-smart',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-Smart-1600VA-5000VA-EN.pdf',
  },
  {
    id: 53,
    type: 'inverter',
    name: 'Phoenix Inverter 48/3000',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 3000,
    wattsPower: 2400,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1580814855_upload_documents_1600_640_q90-Phoenix%20Inverter%2024V%203000VA%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
  {
    id: 54,
    type: 'inverter',
    name: 'Phoenix Inverter 48/5000',
    voltage: 230,
    batteryVoltage: 48,
    vaPower: 5000,
    wattsPower: 4000,
    imgUrl: 'https://www.victronenergy.com/upload/cache/1508854874_upload_documents_1600_640_q90-Phoenix%20Inverter%2024%205000%20%28front%29.png',
    infoUrl: 'https://www.victronenergy.com/inverters/phoenix-inverter-12v-24v-48v-800va-3kva',
    datasheetUrl: 'https://www.victronenergy.com/upload/documents/Datasheet-Phoenix-Inverter-1200VA-5000VA-EN.pdf',
  },
];

export default inverters;
