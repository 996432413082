import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfFile from './PdfFile';
import { GlobalContext } from '../../../../../context/GlobalContext';

function PdfButton() {
  const global = React.useContext(GlobalContext);
  const { state, results, loadsPhases, invertersSuggest } = global;

  return (
    <PDFDownloadLink
      document={ <PdfFile
        state={ state }
        results={ results }
        loadsPhases={ loadsPhases }
        inverters={ invertersSuggest.suggestedInverters }
      /> }
      fileName="OFF-GRID-CALCULATOR-RESULTS"
    >
      <button
        type="button"
        style={ {
          backgroundColor: '#FA8128',
          border: '1px solid #ED7014',
          // marginTop: '16px',
          marginLeft: '16px',
          width: 'auto',
          fontFamily: 'Museo Sans Rounded',
          fontSize: '16px',
          color: '#FFF',
        } }
        className="btn btn-primary"
      >
        Generate PDF
      </button>
    </PDFDownloadLink>
  );
}

export default PdfButton;
