import React from 'react';
import PropTypes from 'prop-types';

function CloseButton({ closeModal }) {
  return (
    <button
      type="button"
      className="btn btn-danger"
      style={ {
        width: '90px',
        marginTop: '12px',
        fontSize: '16px',
      } }
      onClick={ () => closeModal() }
    >
      Cerrar
    </button>
  );
}

CloseButton.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CloseButton;
