import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResultLoadsTable.module.css';

function ResultLoadsTable({ name, loads }) {
  return (
    <div className={ styles['loads-table-container'] }>
      <h4
        className={ styles['phase-title'] }
      >
        {`Cargas conectadas en la fase ${name}`}
      </h4>
      <table className={ `"table table-borderless" ${styles['loads-table']}` }>
        <thead>
          <tr>
            <th className={ styles['loads-table-col-name'] } scope="col">Nombre</th>
            <th scope="col">Potencia en esta fase (W)</th>
            <th scope="col">Fases conectadas</th>
          </tr>
        </thead>
        <tbody>
          { loads.map(({ loadName, loadPower, loadPhases }) => (
            <tr key={ loadName }>
              <td>{ loadName }</td>
              <td>{ loadPower }</td>
              <td>{ loadPhases }</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <h4 className={ styles['phase-power-title'] }>
        {`Potencia total: ${totalPower} W`}
      </h4> */}
    </div>
  );
}

ResultLoadsTable.propTypes = {
  name: PropTypes.string.isRequired,
  loads: PropTypes.node.isRequired,
};

export default ResultLoadsTable;
