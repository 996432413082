/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';

function SpanishHeader({ setLanguage, language }) {
  const [navBarOn, setNavBarOn] = useState(true);

  useEffect(() => {
    const MAX_SCREEN_SIZE = 900;
    if (window.innerWidth < MAX_SCREEN_SIZE) setNavBarOn(false);
  }, []);

  const showNavBar = () => {
    setNavBarOn(!navBarOn);
  };

  const navBar = (
    <nav className={ styles.nav }>
      <a
        href="/"
        className={ styles['nav-option'] }
      >
        Off-Grid Calculator
      </a>
      <a
        href="https://www.victronenergy.com.es/where-to-buy"
        target="_blank"
        rel="noreferrer"
        className={ styles['nav-option'] }
      >
        ¿Donde comprar?
      </a>
      <select
        name="language"
        className={ styles['select-language'] }
        onChange={ ({ target: { value } }) => setLanguage(value) }
        value={ language }
      >
        <option value="Español">Español</option>
        <option value="English" selected>English</option>
        <option value="Português">Português</option>
      </select>
    </nav>
  );

  const navBarBtn = (
    <button
      type="button"
      onClick={ () => showNavBar() }
      className={ styles['nav-bar-button'] }
    >
      <i className="bi bi-list"> </i>
    </button>
  );

  return (
    <header className={ styles['header-container'] }>
      <div className={ styles['header-container-first-line'] }>
        <img
          src="https://www.victronenergy.com/static/img/victron-logo-header.svg"
          alt="logo"
        />
        { navBarBtn }
      </div>
      <div className={ styles['header-container-second-line'] }>
        { navBarOn && navBar }
      </div>
    </header>
  );
}

SpanishHeader.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default SpanishHeader;
