import React, { useContext } from 'react';
import LoadComponent from './LoadComponent';
import { GlobalContext } from '../../../../../context/GlobalContext';
import styles from './LoadsTable.module.css';

function LoadsTable() {
  const global = useContext(GlobalContext);
  const { loads, state } = global;
  const { inverterCharger } = state;

  return (
    <div>
      <table className={ `"table table-borderless" ${styles['loads-table']}` }>
        <thead>
          <tr>
            <th className={ styles['loads-table-col-name'] } scope="col">Name</th>
            <th scope="col">Power (W)</th>
            <th scope="col">Hours of use</th>
            { inverterCharger && <th scope="col">Phases</th>}
            <th scope="col"> </th>
          </tr>
        </thead>
        <tbody>
          { loads.map((load, index) => (
            <LoadComponent load={ load } key={ index } id={ index } />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LoadsTable;
