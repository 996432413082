/* eslint-disable react/prop-types */
import styles from './InverterCard.module.css';
import InverterRecommendation from './InverterRecommendation';

function InverterCard({
  imgUrl,
  name,
  infoUrl,
  vaPower,
  batteryVoltage,
  voltage,
  chargeCurrentHouseBattery,
  datasheetUrl,
  recommendation }) {
  const maxScreenSize = 450;
  const btnStyle = {
    backgroundColor: 'white',
    border: '1px solid #e6e5e1',
    width: 'auto',
    fontFamily: 'Museo Sans Rounded',
    fontSize: window.innerWidth < maxScreenSize ? '10px' : '12px',
    marginRight: '8px',
    marginLeft: '8x',
    marginBottom: '8px',
    color: 'black',
    hover: {
      backgroundColor: 'black',
    },
  };

  return (
    <div className={ styles['inverter-card-container'] }>
      <div className={ styles['inverter-card-img-container'] }>
        <img
          className={ styles['inverter-image'] }
          src={ imgUrl }
          alt={ name }
          style={ { width: '150px' } }
        />
      </div>
      <div className={ styles['inverter-card-params'] }>
        <a href={ infoUrl } target="_blank" rel="noreferrer"><h5>{ name }</h5></a>
        <div>
          <h6>{`Potencia: ${vaPower} VA`}</h6>
          <h6>{`Tensíon de salida: ${voltage} V`}</h6>
          <h6>{`Tensíon del batería: ${batteryVoltage} V`}</h6>
          { chargeCurrentHouseBattery
          && <h6>{`Cargando corriente eléctrica: ${chargeCurrentHouseBattery} A`}</h6> }
        </div>
        <div className={ styles['buttons-container'] }>
          <a href={ datasheetUrl } target="_blank" rel="noreferrer">
            <button
              className="btn btn-primary"
              style={ btnStyle }
            >
              Datasheet
            </button>
          </a>
          <a
            className={ styles['btn-info'] }
            href="https://www.victronenergy.com.es/where-to-buy"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="btn btn-primary"
              style={ btnStyle }
            >
              Donde comprar
            </button>
          </a>
        </div>
        { recommendation
        && <InverterRecommendation recommendation={ recommendation } /> }
      </div>
    </div>
  );
}

export default InverterCard;
