export const batteryEnergy = (param) => {
  const {
    totalLoadsEnergy,
    batteryAutonomy,
    batteryDischarge,
    batteryTension,
    batteryEfficiency,
  } = param;
  const HOURS_PER_DAY = 24;
  const numerator = totalLoadsEnergy * (batteryAutonomy / HOURS_PER_DAY);
  const denominator = (batteryDischarge / 100)
  * batteryTension * (batteryEfficiency / 100);
  const batteryEnergyValue = numerator / denominator;
  return Math.round(batteryEnergyValue);
};
