/* eslint-disable no-magic-numbers */
import React from 'react';
import styles from './SectionLoads.module.css';
import Input from '../../form/Input';
import Select from '../../form/Select';
import { GlobalContext } from '../../../../context/GlobalContext';
import LoadsTable from './components/LoadsTable';
import { verifyAddLoad } from '../../../../helpers/verifyAddLoad';
import InverterPowerModal from '../../modal/inverter-power/InverterPowerModal';
import {
  isInputMaxLength,
  isInputNegative,
  isInputNotNumber,
  isInputNumberMax,
  isInputNumberNotInteger,
} from '../../../../helpers/verifyInputs';

function SectionLoads() {
  const global = React.useContext(GlobalContext);
  const { state: {
    loadName,
    loadPower,
    loadUse,
    loadQuantity,
    loadPhases,
    inverterCharger,
  } } = global;

  const { loads, state, inverterPowerModalIsOpen, setInverterPowerModalIsOpen } = global;

  const wrongInputSyle = { border: '1px solid #dc3545' };

  return (
    <>
      { inverterPowerModalIsOpen && <InverterPowerModal
        modalIsOpen={ inverterPowerModalIsOpen }
        setIsOpen={ setInverterPowerModalIsOpen }
      /> }
      <div className={ styles['div-data'] }>
        <h3>Cargas</h3>
        <Input
          id="load-name-input"
          label="Nome da carga"
          type="text"
          placeHolder="Digite o nome da carga"
          name="loadName"
          inputValue={ loadName }
          maxInputLength={ 24 }
          componentStyles={ isInputMaxLength(loadName, 24) && wrongInputSyle }
        />
      </div>

      <div className={ styles['div-data'] }>
        <div className={ styles['div-double-input'] }>
          <Input
            id="load-power-input"
            label="Potência nominal (W)"
            placeHolder="500"
            type="number"
            max={ 10000 }
            min={ 1 }
            name="loadPower"
            inputValue={ loadPower }
            componentStyles={
              (isInputNegative(loadPower)
              || isInputNumberMax(loadPower, 10000)
              || isInputNotNumber(loadPower))
               && wrongInputSyle
            }
          />
          <Input
            id="load-hours-input"
            label="Horas de uso por dia"
            placeHolder="1"
            type="number"
            max={ 24 }
            min={ 1 }
            name="loadUse"
            inputValue={ loadUse }
            maxInputLength={ 2 }
            componentStyles={
              (isInputNegative(loadUse)
              || isInputNumberMax(loadUse, 24)
              || isInputNotNumber(loadUse))
               && wrongInputSyle
            }
          />
        </div>
      </div>

      <div className={ styles['div-data'] }>
        <div className={ styles['div-double-input'] }>
          <Input
            id="load-quantity-input"
            label="Quantidade"
            placeHolder="1"
            type="number"
            name="loadQuantity"
            inputValue={ loadQuantity }
            max={ 10 }
            min={ 1 }
            componentStyles={
              (isInputNegative(loadQuantity)
              || isInputNumberMax(loadQuantity, 10)
              || isInputNotNumber(loadQuantity)
              || isInputNumberNotInteger(loadQuantity)
              )
               && wrongInputSyle
            }
          />
          <Select
            id="load-phases-input"
            label="Número de fases"
            options={ ['Monofásico (1)', 'Bifásico (2)', 'Trifásico (3)'] }
            name="loadPhases"
            disabled={ !inverterCharger }
            inputValue={ loadPhases }
          />
        </div>
      </div>

      <div className={ styles['div-button'] }>
        <button
          type="button"
          className="btn btn-success"
          style={ {
            fontFamily: 'Museo Sans Rounded',
            fontSize: '14px',
          } }
          onClick={ () => global.addLoad() }
          disabled={ verifyAddLoad(state) }
        >
          Adicionar
        </button>
      </div>
      {
        loads.length === 0 && (
          <div className={ styles['load-warning-container'] }>
            Adicione pelo menos uma carga no sistema
          </div>
        )
      }
      <div className={ styles['table-wrapper'] }>
        <LoadsTable />
      </div>
    </>
  );
}

export default SectionLoads;
