/* eslint-disable no-magic-numbers */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../../context/GlobalContext';
import styles from './Select.module.css';

function Select({ id, label, options, inputValue, name, disabled }) {
  const global = React.useContext(GlobalContext);

  return (
    <div
      className={ `form-group ${styles['form-input-container']}` }
      style={ {
        width: id === 'battery-composition-input' ? '100%' : '' } }
    >
      <label htmlFor={ id }>
        { label }
      </label>

      <select
        className={ ` ${styles['form-input']} form-select` }
        style={ { color: '#003366' } }
        value={ inputValue }
        name={ name }
        disabled={ disabled }
        onChange={ (event) => global.handleChange(event) }
      >
        { options.map((option) => (
          <option
            key={ option }
            value={ name === 'inverterVoltage'
              ? (option === '90 a 140' ? 120 : 230) : option }
          >
            { option }
          </option>
        ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.func.isRequired,
  inputValue: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Select;
