import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import CloseButton from '../CloseButton';
import styles from './InverterPowerModal.module.css';

function InverterPowerModal({ modalIsOpen, setIsOpen }) {
  function closeModal() {
    setIsOpen(false);
  }

  const modalStyles = {
    content: {
      fontFamily: 'Museo Sans Rounded',
      fontSize: '14px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '500px',
      backgroundColor: '#FAF9F6',
      width: 'auto',
    },
  };

  const iconStyles = {
    fontSize: '100px',
    color: '#f8bb86',
  };

  return (
    <Modal
      ariaHideApp={ false }
      isOpen={ modalIsOpen }
      onRequestClose={ () => closeModal() }
      style={ modalStyles }
    >
      <div className={ styles['modal-container'] }>
        <i className="bi bi-exclamation-circle" style={ iconStyles }> </i>
        <h4>
          The calculated power of the inverters exceeded the limit of 45kVA
        </h4>
        <p>
          Remove some loads from the system or contact a
          <span> </span>
          <a
            href="https://www.victronenergy.com.es/where-to-buy"
            target="_blank"
            rel="noreferrer"
            style={ { color: 'rgb(250, 129, 40)' } }
          >
            Victron Energy distributor
          </a>
        </p>
        <div className={ styles['buttons-container'] }>
          <CloseButton closeModal={ () => closeModal() } />
        </div>
      </div>
    </Modal>
  );
}

InverterPowerModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default InverterPowerModal;
