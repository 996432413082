/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable react-func/max-lines-per-function */

import { inverterPower } from './inverterPower';

export const phasesCalculator = (loads) => {
  const SINGLE_PHASE = 1;
  const TWO_PHASES = 2;
  const THREE_PHASES = 3;

  const phases = loads.map((load) => {
    if (load.loadPhases && load.loadPhases.includes('1')) load = SINGLE_PHASE;
    if (load.loadPhases && load.loadPhases.includes('2')) load = TWO_PHASES;
    if (load.loadPhases && load.loadPhases.includes('3')) load = THREE_PHASES;
    return load;
  });

  const systemPhasesNumber = Math.max(...phases);

  const phaseOneLoads = [];
  const phaseTwoLoads = [];
  const phaseThreeLoads = [];

  loads.forEach((load) => {
    const { loadPower, loadPhases } = load;
    const phaseOnePower = phaseOneLoads.reduce((acc, cv) => acc
    + cv.loadPower, 0);
    const phaseTwoPower = phaseTwoLoads.reduce((acc, cv) => acc
    + cv.loadPower, 0);
    const phaseThreePower = phaseThreeLoads.reduce((acc, cv) => acc
    + cv.loadPower, 0);

    // Single phase system
    if (systemPhasesNumber === SINGLE_PHASE) {
      phaseOneLoads.push({ ...load, loadPower, loadPhases: '1' });
    }

    // Two phases system
    if (systemPhasesNumber === TWO_PHASES) {
      if (loadPhases.includes('1')) {
        if (phaseOnePower <= phaseTwoPower) {
          phaseOneLoads.push({ ...load, loadPower, loadPhases: '1' });
        }
        if (phaseOnePower > phaseTwoPower) {
          phaseTwoLoads.push({ ...load, loadPower, loadPhases: '2' });
        }
      } else if (loadPhases.includes('2')) {
        phaseOneLoads.push(
          { ...load, loadPower: Math.round(loadPower / TWO_PHASES), loadPhases: '1, 2' },
        );
        phaseTwoLoads.push(
          { ...load, loadPower: Math.round(loadPower / TWO_PHASES), loadPhases: '1, 2' },
        );
      }
    }

    // Three phases system
    if (systemPhasesNumber === THREE_PHASES) {
      if (loadPhases.includes('1')) {
        if (phaseOnePower <= phaseTwoPower && phaseOnePower <= phaseThreePower) {
          phaseOneLoads.push({ ...load, loadPhases: '1' });
        }
        if (phaseTwoPower < phaseOnePower && phaseTwoPower <= phaseThreePower) {
          phaseTwoLoads.push({ ...load, loadPhases: '2' });
        }
        if (phaseThreePower < phaseOnePower && phaseThreePower < phaseTwoPower) {
          phaseThreeLoads.push({ ...load, loadPhases: '3' });
        }
      } else if (loadPhases.includes('2')) {
        if (phaseOnePower <= phaseThreePower && phaseTwoPower <= phaseThreePower) {
          phaseOneLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '1, 2' },
          );
          phaseTwoLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '1, 2' },
          );
        }
        if (phaseOnePower <= phaseTwoPower && phaseThreePower < phaseTwoPower) {
          phaseOneLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '1, 3' },
          );
          phaseThreeLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '1, 3' },
          );
        }
        if (phaseTwoPower < phaseOnePower && phaseThreePower < phaseOnePower) {
          phaseTwoLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '2, 3' },
          );
          phaseThreeLoads.push(
            { ...load,
              loadPower: Math.round(loadPower / TWO_PHASES),
              loadPhases: '2, 3' },
          );
        }
      } else if (loadPhases.includes('3')) {
        phaseOneLoads.push(
          { ...load,
            loadPower: Math.round(loadPower / THREE_PHASES),
            loadPhases: '1, 2, 3' },
        );
        phaseTwoLoads.push(
          { ...load,
            loadPower: Math.round(loadPower / THREE_PHASES),
            loadPhases: '1, 2, 3' },
        );
        phaseThreeLoads.push(
          { ...load,
            loadPower: Math.round(loadPower / THREE_PHASES),
            loadPhases: '1, 2, 3' },
        );
      }
    }
  });

  const phaseOnePower = Math.round(phaseOneLoads
    .reduce((acc, cv) => acc + cv.loadPower, 0));
  const phaseTwoPower = Math.round(phaseTwoLoads
    .reduce((acc, cv) => acc + cv.loadPower, 0));
  const phaseThreePower = Math.round(phaseThreeLoads
    .reduce((acc, cv) => acc + cv.loadPower, 0));

  // Calling inverterPower.js
  const inverterPowerValue = inverterPower(
    Math.max(...[phaseOnePower, phaseTwoPower, phaseThreePower]),
  );

  const loadsPhases = {
    systemPhasesNumber,
    phaseOneLoads,
    phaseTwoLoads,
    phaseThreeLoads,
    phaseOnePower,
    phaseTwoPower,
    phaseThreePower,
    inverterPower: inverterPowerValue,
  };

  return {
    loadsPhases,
  };
};
