import React from 'react';
import styles from './Filters.module.css';
import CheckBox from '../../../form/CheckBox';
import { GlobalContext } from '../../../../../context/GlobalContext';

function Filters() {
  const global = React.useContext(GlobalContext);
  const { state:
    {
      invertersFilters,
      inverterCharger,
      acInputsInvertersFilter,
    }, handleChange } = global;

  return (
    <div className={ styles['filters-container'] }>
      <CheckBox
        id="inverters-filters-check"
        label={ <h4>Apply filters to investors</h4> }
        name="invertersFilters"
        inputValue={ invertersFilters }
        checked={ invertersFilters }
        handleChange={ (event) => handleChange(event) }
        disabled={ !inverterCharger }
      />
      <h5
        className={ (!invertersFilters || !inverterCharger)
          && styles['inverters-filter-title-disabled'] }
      >
        Number of inputs for other AC sources
      </h5>
      <div className={ styles['ac-inputs-inverters-filter-container'] }>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="acInputsInvertersFilter"
            id="ac-inputs-inverters-filter-radio-1"
            value={ 1 }
            onClick={ (event) => handleChange(event) }
            disabled={ !invertersFilters || !inverterCharger }
            checked={ acInputsInvertersFilter === 1 }
          />
          <label
            className="form-check-label"
            htmlFor="ac-inputs-inverters-filter-radio-1"
          >
            One
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="acInputsInvertersFilter"
            id="ac-inputs-inverters-filter-radio-2"
            value={ 2 }
            onClick={ (event) => handleChange(event) }
            disabled={ !invertersFilters || !inverterCharger }
            checked={ acInputsInvertersFilter === 2 }
          />
          <label
            className="form-check-label"
            htmlFor="ac-inputs-inverters-filter-radio-2"
          >
            Two
          </label>
        </div>
      </div>
    </div>
  );
}

export default Filters;
