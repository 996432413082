import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadComponent.module.css';
import { GlobalContext } from '../../../../../context/GlobalContext';

function LoadComponent({ load }) {
  const global = React.useContext(GlobalContext);
  const { loadName, loadPower, loadUse, loadPhases, id } = load;
  const { inverterCharger } = global.state;

  let phases = 1;
  const TWO_PHASES = 2;
  const THREE_PHASES = 3;

  if (loadPhases === 'Bifásico (2)') phases = TWO_PHASES;
  if (loadPhases === 'Trifásico (3)') phases = THREE_PHASES;

  return (
    <tr className={ styles['load-component'] }>
      <td className={ styles['loads-table-line-load'] }>{ loadName }</td>
      <td>{ loadPower }</td>
      <td>{ loadUse }</td>
      {inverterCharger && <td>{ phases }</td>}
      <td>
        <button
          type="button"
          onClick={ () => global.removeLoad(id) }
          className={ `btn btn-danger ${styles['delete-btn']}` }
          style={ {
            marginRight: '10%',
            padding: '0px',
            border: 'none',
            background: 'none',
            color: '#DC3545',
            fontSize: '12px',
          } }
        >
          <i
            className="bi bi-trash3-fill"
            style={ { backgroundColor: 'inherit', marginRight: '4px' } }
          />
        </button>
      </td>
    </tr>
  );
}

LoadComponent.propTypes = {
  load: PropTypes.string.isRequired,
};

export default LoadComponent;
