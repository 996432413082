import React from 'react';
import styles from './ResultsList.module.css';
import { GlobalContext } from '../../../../../context/GlobalContext';
import ResultCard from './ResultCard';

function CalulationResults() {
  const global = React.useContext(GlobalContext);
  const {
    totalLoadsEnergy,
    pvPower,
    batteryEnergy,
  } = global.results;

  const {
    inverterVoltage,
    hsp,
    batteryAutonomy,
    batteryTension,
    batteryDischarge,
    batteryEfficiency,
  } = global.state;

  const {
    systemPhasesNumber,
    phaseOnePower,
    phaseTwoPower,
    phaseThreePower,
    inverterPower,
  } = global.loadsPhases;

  const results = [{
    name: 'Potência total das cargas',
    value: `${phaseOnePower + phaseTwoPower + phaseThreePower} W`,
    info: false,
  },
  {
    name: 'Número de Fases do Sistema',
    value: `${systemPhasesNumber}`,
    info: false,
  },
  {
    name: 'Número de Inversores por fase',
    value: '1',
    info: false,
  },
  {
    name: 'Número total de inversores',
    value: `${systemPhasesNumber}`,
    info: false,
  },
  {
    name: 'Potência de cada Inversor',
    value: `${inverterPower} W`,
    info: false,
  },
  {
    name: 'Energia total consumida por dia',
    value: `${totalLoadsEnergy} Wh`,
    info: false,
  },
  {
    name: 'Potência do sistema fotovoltaico',
    value: `${pvPower} W`,
    info: false,
  },
  {
    name: 'Tensão do Inversor',
    value: `${inverterVoltage} V`,
    info: false,
  },
  {
    name: 'Horas de Sol Pleno (HSP)',
    value: `${hsp}`,
    info: false,
  },
  {
    name: 'Autonomia do Sistema',
    value: `${batteryAutonomy} horas`,
    info: false,
  },
  {
    name: 'Capacidade do Banco de Baterias',
    value: `${batteryEnergy} Ah`,
    info: false,
  },
  {
    name: 'Tensão do Banco de Baterias',
    value: `${batteryTension} V`,
    info: false,
  },
  {
    name: 'Profundidade de Descarga do Banco de Baterias',
    value: `${batteryDischarge} %`,
    info: false,
  },
  {
    name: 'Eficiência do Banco de Baterias',
    value: `${batteryEfficiency} %`,
    info: false,
  },
  {
    name: 'Consultar balanceamento de fases',
    info: true,
  },
  ];

  return (
    <div className={ styles['results-list-container'] }>
      <h4>Cálculos</h4>
      <table className={ `"table table-borderless" ${styles['results-table']}` }>
        <tbody>
          { results.map(({ name, value, info }) => (
            <ResultCard
              resultName={ name }
              resultValue={ value }
              key={ name }
              info={ info }
            />
          )) }
        </tbody>
      </table>
    </div>
  );
}

export default CalulationResults;
