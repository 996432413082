import React from 'react';
import styles from './ResultsList.module.css';
import { GlobalContext } from '../../../../../context/GlobalContext';
import ResultCard from './ResultCard';

function CalulationResults() {
  const global = React.useContext(GlobalContext);
  const {
    totalLoadsEnergy,
    pvPower,
    batteryEnergy,
  } = global.results;

  const {
    inverterVoltage,
    hsp,
    batteryAutonomy,
    batteryTension,
    batteryDischarge,
    batteryEfficiency,
  } = global.state;

  const {
    systemPhasesNumber,
    phaseOnePower,
    phaseTwoPower,
    phaseThreePower,
    inverterPower,
  } = global.loadsPhases;

  const results = [{
    name: 'Total load power',
    value: `${phaseOnePower + phaseTwoPower + phaseThreePower} W`,
    info: false,
  },
  {
    name: 'Number of System Phases',
    value: `${systemPhasesNumber}`,
    info: false,
  },
  {
    name: 'Number of inverters per phase',
    value: '1',
    info: false,
  },
  {
    name: 'Total number of inverters',
    value: `${systemPhasesNumber}`,
    info: false,
  },
  {
    name: 'Power of each inverter',
    value: `${inverterPower} W`,
    info: false,
  },
  {
    name: 'Total energy consumed per day',
    value: `${totalLoadsEnergy} Wh`,
    info: false,
  },
  {
    name: 'Photovoltaic System Power',
    value: `${pvPower} W`,
    info: false,
  },
  {
    name: 'Inverter Voltage',
    value: `${inverterVoltage} V`,
    info: false,
  },
  {
    name: 'Sun hours',
    value: `${hsp}`,
    info: false,
  },
  {
    name: 'System Autonomy',
    value: `${batteryAutonomy} hours`,
    info: false,
  },
  {
    name: 'Battery Bank Capacity',
    value: `${batteryEnergy} Ah`,
    info: false,
  },
  {
    name: 'Battery Bank Voltage',
    value: `${batteryTension} V`,
    info: false,
  },
  {
    name: 'Battery Bank Depth of Discharge',
    value: `${batteryDischarge} %`,
    info: false,
  },
  {
    name: 'Battery Bank Efficiency',
    value: `${batteryEfficiency} %`,
    info: false,
  },
  {
    name: 'Check phase balance',
    info: true,
  },
  ];

  return (
    <div className={ styles['results-list-container'] }>
      <h4>Calculations</h4>
      <table className={ `"table table-borderless" ${styles['results-table']}` }>
        <tbody>
          { results.map(({ name, value, info }) => (
            <ResultCard
              resultName={ name }
              resultValue={ value }
              key={ name }
              info={ info }
            />
          )) }
        </tbody>
      </table>
    </div>
  );
}

export default CalulationResults;
