/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import { useContext, useState } from 'react';
import CheckBox from '../../form/CheckBox';
import Input from '../../form/Input';
import Select from '../../form/Select';
import { GlobalContext } from '../../../../context/GlobalContext';
import styles from './SectionData.module.css';
import InverterChargerWarningModal
  from '../../modal/inverter-charger-warning/InverterChargerWarningModal';
import {
  isInputMaxLength,
  isInputNegative,
  isInputNotNumber,
  isInputNumberMax,
} from '../../../../helpers/verifyInputs';

function SectionData() {
  const global = useContext(GlobalContext);
  const { state: {
    clientName,
    technicalName,
    systemInformations,
    hsp,
    inverterVoltage,
    inverterCharger,
    batteryAutonomy,
    batteryTension,
    batteryComposition,
    batteryDischarge,
    batteryEfficiency,
  } } = global;
  const { setState, reset } = global;
  const [modalIsOpen, setIsOpen] = useState(false);

  const DOUBLE_INPUT_CLASS = 'div-double-input';
  const wrongInputSyle = { border: '1px solid #dc3545' };

  function openModal({ target }) {
    const value = target.checked;
    if (!value) {
      setIsOpen(true);
    } else {
      setState((prevState) => ({ ...prevState, inverterCharger: value }));
    }
  }

  return (
    <>
      { modalIsOpen && <InverterChargerWarningModal
        modalIsOpen={ modalIsOpen }
        setIsOpen={ setIsOpen }
      /> }
      <div className={ styles['div-data'] }>
        <div className={ styles['title-container'] }>
          <h3>Datos Generales</h3>
          <button
            className={ styles['btn-reset'] }
            type="button"
            onClick={ reset }
          >
            Reset
          </button>
        </div>
        <Input
          id="client-input"
          label="Nombre del Cliente (opcional)"
          type="text"
          placeHolder="Ingrese el nombre y apellido del cliente"
          name="clientName"
          inputValue={ clientName }
          maxInputLength={ 80 }
          componentStyles={ isInputMaxLength(clientName, 80) && wrongInputSyle }
        />
        <Input
          id="technical-input"
          label="Técnico Responsable (opcional)"
          type="text"
          placeHolder="Ingrese el nombre y apellido del técnico responsable"
          name="technicalName"
          inputValue={ technicalName }
          maxInputLength={ 80 }
          componentStyles={ isInputMaxLength(technicalName, 80) && wrongInputSyle }
        />
        <Input
          id="information-input"
          label="Informaciones Adicionales (opcional)"
          type="text"
          placeHolder="Ingrese información adicional sobre el sistema"
          name="systemInformations"
          inputValue={ systemInformations }
          maxInputLength={ 80 }
          componentStyles={ isInputMaxLength(systemInformations, 80) && wrongInputSyle }
        />
      </div>

      <div className={ styles['div-data'] }>
        <h3>Datos de Sistema</h3>
        <div className={ styles[DOUBLE_INPUT_CLASS] }>
          <Input
            id="hsp-input"
            label="Horas de Pleno Sol (HSP)"
            placeHolder="5,00"
            type="number"
            name="hsp"
            inputValue={ hsp }
            max={ 99 }
            min={ 1 }
            step={ 1 }
            componentStyles={
              (isInputNegative(hsp)
              || isInputNumberMax(hsp, 99)
              || isInputNotNumber(hsp))
               && wrongInputSyle
            }
          />
          <Select
            id="inverter-tension-input"
            label="Tensíon del Inversor (V)"
            options={ ['90 a 140', '187 a 265'] }
            // options={ [120, 230] }
            name="inverterVoltage"
            inputValue={ inverterVoltage }
          />
        </div>
      </div>

      <div className={ styles['div-data'] }>
        <div className={ styles.teste }>
          <h3>Baterías</h3>
        </div>
        <div className={ styles['div-single-input'] }>
          <CheckBox
            id="inverter-charger-check"
            label="Inversor con Cargador de Batería"
            name="inverterCharger"
            inputValue={ inverterCharger }
            checked={ inverterCharger }
            disabled={ false }
            handleChange={ (event) => openModal(event) }
          />
        </div>
        <div className={ styles[DOUBLE_INPUT_CLASS] }>
          <Input
            id="battery-autonomy-input"
            label="Horas de Autonomia"
            placeHolder="2"
            type="number"
            max={ 240 }
            min={ 1 }
            step={ 1 }
            name="batteryAutonomy"
            inputValue={ batteryAutonomy }
            componentStyles={
              (isInputNegative(batteryAutonomy)
              || isInputNumberMax(batteryAutonomy, 240)
              || isInputNotNumber(batteryAutonomy))
               && wrongInputSyle
            }
          />
          <Select
            id="battery-tension-input"
            label="Tensíon (V)"
            options={ [12, 24, 48] }
            name="batteryTension"
            inputValue={ batteryTension }
          />
        </div>
        <Select
          id="battery-composition-input"
          label="Tipo"
          options={ ['Litio', 'Plomo y ácido', 'Otro'] }
          name="batteryComposition"
          inputValue={ batteryComposition }
        />
        <div className={ styles[DOUBLE_INPUT_CLASS] }>
          <Input
            id="battery-depth-discharge-input"
            label="Profundidad de Descarga (%)"
            placeHolder="80"
            type="number"
            max={ 99 }
            min={ 1 }
            step={ 1 }
            name="batteryDischarge"
            inputValue={ batteryDischarge }
            componentStyles={
              (isInputNegative(batteryDischarge)
              || isInputNumberMax(batteryDischarge, 99)
              || isInputNotNumber(batteryDischarge))
               && wrongInputSyle
            }
          />
          <Input
            id="battery-efficiency-input"
            label="Eficiencia (%)"
            placeHolder="95"
            type="number"
            max={ 99 }
            min={ 1 }
            step={ 1 }
            name="batteryEfficiency"
            inputValue={ batteryEfficiency }
            componentStyles={
              (isInputNegative(batteryEfficiency)
              || isInputNumberMax(batteryEfficiency, 99)
              || isInputNotNumber(batteryEfficiency))
               && wrongInputSyle
            }
          />
        </div>
      </div>
    </>
  );
}

export default SectionData;
