import PropTypes from 'prop-types';
import styles from './InverterRecommendation.module.css';

function InverterRecommendation({ recommendation }) {
  const verifyColorStyle = recommendation.includes('No') ? '#DB0F27' : '#028A0F';
  const colorStyle = { color: verifyColorStyle };

  return (
    <div
      className={ styles['power-advice'] }
      style={ colorStyle }
    >
      { recommendation.includes('No')
        ? <i className="bi bi-x-circle"> </i>
        : <i className="bi bi-check-all"> </i> }
      <h5>{ recommendation }</h5>
    </div>
  );
}

InverterRecommendation.propTypes = {
  recommendation: PropTypes.string.isRequired,
};

export default InverterRecommendation;
