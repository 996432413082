/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#faf9f6',
    fontSize: '10px',
  },
  /* topo | direita | inferior | esquerda */
  section: {
    margin: '20px',
    padding: 10,
    flexGrow: 1,
    backgroundColor: '#faf9f6',
    border: '1px solid #e6e5e1',
    borderRadius: '12px',
  },
  subSection: {
    border: '1px solid #e6e5e1',
    borderRadius: '12px',
    backgroundColor: '#FFF',
    marginBottom: '12px',
    paddingBottom: '10px',
    marginTop: '12px',
  },
  systemInformations: {
    flexDirection: 'column',
    marginBottom: '12px',
    marginLeft: '12px',
  },
  pdfTitle: {
    fontSize: '16px',
    color: '#4790d0',
    fontWeight: 1500,
    marginBottom: '16px',
    marginTop: '4px',
  },
  pdfsubTitle: {
    fontSize: '12px',
    fontWeight: 900,
    marginTop: '16px',
    marginLeft: '12px',
    marginBottom: '12px',
    color: '#4790d0',
  },
  results: {
    flexDirection: 'column',
    marginBottom: '12px',
    marginLeft: '16px',
  },
  loadName: {
    flexDirection: 'column',
    marginBottom: '12px',
    marginLeft: '16px',
  },
  filterAdvice: {
    flexDirection: 'column',
    marginBottom: '12px',
    color: '#64635f',
    marginLeft: '12px',
  },
});

function PdfFile({ state, results, loadsPhases, inverters }) {
  const {
    batteryAutonomy,
    batteryDischarge,
    batteryEfficiency,
    batteryTension,
    clientName,
    hsp,
    inverterVoltage,
    systemInformations,
    technicalName,
    invertersFilters,
    inverterCharger,
  } = state;

  const {
    totalLoadsEnergy,
    pvPower,
    batteryEnergy,
  } = results;

  const {
    systemPhasesNumber,
    phaseOnePower,
    phaseTwoPower,
    phaseThreePower,
    inverterPower,
    phaseOneLoads,
    phaseTwoLoads,
    phaseThreeLoads,
  } = loadsPhases;

  const resultList = [
    {
      name: 'Potencia total das cargas',
      value: `${phaseOnePower + phaseTwoPower + phaseThreePower} W`,
    },
    {
      name: 'Número de Fases do Sistema',
      value: `${systemPhasesNumber}`,
    },
    {
      name: 'Número de Inversores por fase',
      value: '1',
    },
    {
      name: 'Número total de Inversores',
      value: `${systemPhasesNumber}`,
    },
    {
      name: 'Potência de cada Inversor',
      value: `${inverterPower} W`,
    },
    {
      name: 'Energía total consumida por dia',
      value: `${totalLoadsEnergy} Wh`,
    },
    {
      name: 'Potência do Sistema Fotovoltaico',
      value: `${pvPower} W`,
    },
    {
      name: 'Tensão do Inversor',
      value: `${inverterVoltage} V`,
    },
    {
      name: 'Horas de Pleno Sol',
      value: `${hsp}`,
    },
    {
      name: 'Autonomia do Sistema',
      value: `${batteryAutonomy} horas`,
    },
    {
      name: 'Capacidade do Banco de Baterias',
      value: `${batteryEnergy} Ah`,
    },
    {
      name: 'Tensão do Banco de Baterias',
      value: `${batteryTension} V`,
    },
    {
      name: 'Profundidade de Descarga do Banco de Baterias',
      value: `${batteryDischarge} %`,
    },
    {
      name: 'Eficiência do Banco de Baterias',
      value: `${batteryEfficiency} %`,
    },
  ];

  const phasesObj = [{
    name: 'Um',
    loads: phaseOneLoads,
    totalPower: phaseOnePower,
  },
  {
    name: 'Dois',
    loads: phaseTwoLoads,
    totalPower: phaseTwoPower,
  },
  {
    name: 'Três',
    loads: phaseThreeLoads,
    totalPower: phaseThreePower,
  },
  ];

  return (
    <Document>
      <Page size="A4" style={ styles.page }>
        <View style={ styles.section }>
          <Text style={ styles.pdfTitle }>
            Resultados
          </Text>
          <Text style={ styles.systemInformations }>
            { `• Nome do cliente: ${clientName} `}
          </Text>
          <Text style={ styles.systemInformations }>
            { `• Técnico responsável: ${technicalName} `}
          </Text>
          <Text style={ styles.systemInformations }>
            { `• Informações adicionais: ${systemInformations} `}
          </Text>
          <View style={ styles.subSection }>
            <Text style={ styles.pdfsubTitle }>
              Cálculos
            </Text>
            { resultList.map(({ name, value }) => (
              <Text style={ styles.results } key={ `pdf-result-${name}` }>
                { `• ${name}: ${value} `}
              </Text>
            )) }
          </View>
          <View style={ styles.subSection }>
            <Text style={ styles.pdfsubTitle }>
              Inversores sugeridos
            </Text>
            { (invertersFilters && inverterCharger)
            && (
              <Text style={ styles.filterAdvice }>
                Aviso: Filtros aplicados
              </Text>
            )}
            { inverters.map(({ name }) => (
              <Text style={ styles.results } key={ `pdf-inverter-${name}` }>
                { `• ${name}` }
              </Text>
            )) }
          </View>
        </View>
      </Page>
      <Page size="A4" style={ styles.page }>
        <View style={ styles.section }>
          <View style={ styles.subSection }>
            { phasesObj.map(({ name, loads, totalPower }) => (
              totalPower !== 0
              && (
                <>
                  <Text style={ styles.pdfsubTitle } key={ `pdf-phase-${name}` }>
                    { `Cargas conectadas na fase ${name}` }
                  </Text>
                  { loads.map((load) => (
                    <Text style={ styles.loadName } key={ `pdf-load-${name}` }>
                      { `• ${load.loadName}: ${load.loadPower} Watts, ${load.loadUse} horas de uso por dia` }
                    </Text>
                  ))}
                </>
              )
            )) }
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PdfFile;
