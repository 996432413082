/* eslint-disable no-magic-numbers */
export const verifyAddLoad = (state) => {
  const { loadName,
    loadPower,
    loadUse,
    loadQuantity,
  } = state;

  const numberInputs = [loadPower,
    loadUse,
    loadQuantity,
  ];

  const verifyName = loadName.length === 0;
  const verifyPower = loadPower > 10000;
  const verifyUse = loadUse > 24;
  const verifyQuantity = loadQuantity > 10;
  const verifyTypeNegative = numberInputs.some((input) => (
    typeof (input) !== 'number' || input <= 0));
  const verifyIntegerQuantity = !Number.isInteger(loadQuantity);

  if (verifyName
    || verifyTypeNegative
    || verifyPower
    || verifyUse
    || verifyQuantity
    || verifyIntegerQuantity
  ) {
    return true;
  }
};
