import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckBox.module.css';

function CheckBox({ id, label, inputValue, name, checked, handleChange, disabled }) {
  return (
    <div className={ `form-check ${styles['form-checkbox-container']}` }>
      <input
        className="form-check-input"
        type="checkbox"
        id={ id }
        value={ inputValue }
        name={ name }
        onChange={ (event) => handleChange(event) }
        // onChange={ (event) => global.handleChange(event) }
        checked={ checked }
        disabled={ disabled }
      />
      <label className="form-check-label" htmlFor={ id }>
        { label }
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputValue: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CheckBox;
